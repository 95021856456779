<template>
  <v-container class="mt-8">
    <v-row justify="center">
      <v-col md="8">  
        <v-card flat outlined>
          <v-row justify="start">
            <v-col md="2" class="pa-0"></v-col>
            <v-col class="pb-0" md="4"
              ><v-text-field
                class="mt-2"
                v-model="name"
                outlined
                dense
                :label="$t('name')"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row justify="start" class="ma-0 mt-2">
            <v-col md="2" class="pa-0"></v-col>
            <v-col
              class="pa-0"
              v-for="(item, index) in type"
              :key="index"
              md="2"
            >
              <v-radio-group v-model="selectedItem" style="height: 30px">
                <v-radio :label="item" :value="item" color="success"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row justify="start" class="ma-0">
            <v-col md="2" class="px-0"></v-col>
            <v-col
              v-for="(item, index) in classifications"
              :key="index"
              class="pa-0 ma-0"
              md="3"
            >
              <v-radio-group v-model="selectedClass">
                <v-radio :label="item" :value="item" color="success"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2" align="center" class="mt-2 mb-5">{{ $t("hr.employee.rules.value") }}:</v-col>
            <v-col md="4">
              <v-text-field
                style="height: 20px !important"
                dense
                outlined
                v-model="value"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <h3 class="mt-2" v-if="selectedClass == 1">const</h3>
              <h3 class="mt-2" v-if="selectedClass == 'عدد الأيام'">
                {{ $t("initiate.Day") }}
              </h3>
              <h3 class="mt-2" v-if="selectedClass == 'نسبة من الراتب'">
                %
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2" align="center" class="mt-2 mb-5">{{ $t('hr.employee.check.account') }} </v-col>
            <v-col md="4">
              <v-autocomplete
              dense
              outlined
              :items="accountItems"
              :loading="accountLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="AccountValue"
              
              @keypress="fetchAutoCompleteAccount($event)"
            >
            </v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
        <v-row justify="center" class="mt-6">
          <v-col cols="2">
            <v-btn color="primary" class="save-button" @click="submit">{{
              $t("save")
            }}</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn @click="cancel" color="error" class="cancel-button">{{
              $t("cancel")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import i18n from '@/i18n';
import debounce from "../../../../../helpers/debounce";


export default {
  data() {
    return {
      accountItems: [],
      accountLoading: false,
      selectedItem: "",
      selectedClass: "",
      type: [i18n.t('add'), i18n.t('rebate')],
      classifications: [i18n.t('percent'), i18n.t('constValue'), i18n.t('numberOfDays')],
      value: "",
      name: "",
      accountList: [],
      AccountList: [],
      AccountValue: "",
      changing_type: "",
      bonus_type: "",
    };
  },
  async created() {
    if (this.$route.params.id != "CreateNewSalaryVariant") {  
      try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete?query=1"
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } 

      await axios.get(`/hr/bonus/${this.$route.params.id}`).then((res) => {
        this.name = res.data.data.name;
        if (res.data.data.changing_type == "increase") {
          this.selectedItem = "إضافة";
        }
        if (res.data.data.changing_type == "decrease") {
          this.selectedItem = "خصم";
        }
        if (res.data.data.bonus_type == "hours") {
          this.selectedClass = "عدد الأيام";
        }
        if (res.data.data.bonus_type == "value") {
          this.selectedClass = "قيمة ثابتة";
        }
        if (res.data.data.bonus_type == "percent") {
          this.selectedClass = "نسبة من الراتب";
        }
        this.value = res.data.data.value;
        this.AccountValue = res.data.data.account.id;
      });
    }

   
  },
  methods: {
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.accountItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountLoading = false;
        }
      }, 500)();
    },
    
    cancel() {
      this.$router.push("/HR/initiate");
    },
    submit() {
      if (this.selectedItem == "إضافة") {
        this.changing_type = "increase";
      }
      if (this.selectedItem == "خصم") {
        this.changing_type = "decrease";
      }
      if (this.selectedClass == "عدد الأيام") {
        this.bonus_type = "hours";
      }
      if (this.selectedClass == "قيمة ثابتة") {
        this.bonus_type = "value";
      }
      if (this.selectedClass == "نسبة من الراتب") {
        this.bonus_type = "percent";
      }
      console.log(this.accountItems);

      if (this.$route.params.id != "CreateNewSalaryVariant") {
        axios
          .put(`/hr/bonus/${this.$route.params.id}`, {
            name: this.name,
            value: this.value,
            account_id: this.AccountValue,
            bonus_type: this.bonus_type,
            changing_type: this.changing_type,
            date: "2023-5-1",
          })
          .then((res) => {
            this.$router.go(-1);
            if (res.status == 200) {
              this.$Notifications(
                "تم تعديل متغير بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              this.$router.push("/HR/initiate");
            }
          });
      }
      if (this.$route.params.id == "CreateNewSalaryVariant") { 
        axios
          .post("/hr/bonus", {
            name: this.name,
            value: this.value,
            account_id: this.AccountValue,
            date: "2023-5-1",
            bonus_type: this.bonus_type,
            changing_type: this.changing_type,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$router.go(-1);
              this.$Notifications(
                "تمت اضافة متغير بنجاح",
                { rtl: true, timeout: 2500 },
                "success"
              );
              (this.name = ""), (this.value = "");
            }
          });
      }
    },
  },
};
</script>